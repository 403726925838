<template>
  <div>
    <!-- <filter-bar
      name="teams"
      :filters="filters"
      :reset="reset"
      :pFiltrer="filtrer"
      :pSearch="search"
    >
    </filter-bar>-->
    <div class="min-h-screen flex-1 mt-1 bg-gray-100 px-3 items-center">
      <div class="bg-white w-fullrounded-lg shadow">
        <div class="h-20 py-3 flex items-center p-6">
          <div class="flex">
            <div class="text-2xl font-bold text-green-700">
              {{ $t("teams") }}
            </div>
          </div>
          <div class="w-full flex justify-end">
            <router-link
              class="flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-600 transition-all duration-300"
              to="/teams/new">
              <i class="material-icons">add</i> {{ $t("new_team") }}
            </router-link>
          </div>
        </div>
        <div>
          <!-- **********************************************************************-->

          <table class="min-w-full leading-normal">
            <thead>
              <tr>
                <th
                  class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                  {{ $t("name") }}
                </th>
                <th
                  class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                  {{ $t("description") }}
                </th>
                <th
                  class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                  {{ $t("sellers") }}
                </th>

                <th
                  class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                  {{ $t("teleconsultant") }}
                </th>
                <th
                  class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                  {{ $t("actions") }}
                </th>
              </tr>
            </thead>
            <tbody v-for="(item, index) in teams" :key="index">
              <tr>
                <td class="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                  <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900">
                    {{ item.name }}
                  </div>
                </td>

                <td class="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                  <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900">
                    {{ item.description }}
                  </div>
                </td>

                <td class="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                  <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
                    <p class="flex whitespace-no-wrap">
                      <button @click="showVariants(index)" :class="$colors.newColor"
                        class="bg-gray-100 rounded-full mr-2 flex items-center text-2xl focus:outline-none p-1 ml-1">
                        <i class="material-icons" style="transition: 0.3s" :style="element && element._id == item._id
                            ? 'transform: rotate(180deg)'
                            : ''
                          ">keyboard_arrow_down</i>
                      </button>
                      <span class="mt-1 text-green-500 font-semibold">
                        {{ item.sellers.length }}
                      </span>
                      <span class="mt-1 ml-2"> {{ $t("seller") }}</span>
                    </p>
                  </div>
                </td>

                <!-- *******************************  Teleconsultant ***********************-->
                <td class="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                  <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
                    <p class="flex whitespace-no-wrap">
                      <button @click="showVariantsTeleconsultant(index)" :class="$colors.newColor"
                        class="bg-gray-100 rounded-full mr-2 flex items-center text-2xl focus:outline-none p-1 ml-1">
                        <i class="material-icons" style="transition: 0.3s" :style="elementTelConsultant &&
                            elementTelConsultant._id == item._id
                            ? 'transform: rotate(180deg)'
                            : ''
                          ">keyboard_arrow_down</i>
                      </button>

                      <span class="mt-1 text-green-500 font-semibold">
                        {{ item.teleConsultants.length }}
                      </span>
                      <span class="mt-1 ml-2">
                        {{ $t("teleconsultant") }}
                      </span>
                    </p>
                  </div>
                </td>

                <!--  *********************************************************************-->

                <td class="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                  <div class="flex">
                    <router-link :class="$colors.actionTable"
                      class="rounded-full flex items-center focus:outline-none w-8 h-8 mr-3 mt-1"
                      :to="{ path: `/teams/edit/${item._id}` }">
                      <i class="material-icons mx-1">edit</i>
                    </router-link>

                    <button @click="remove(item._id, item.country)" :class="loading ? 'noselect' : ''"
                      class="rounded-full flex items-center focus:outline-none w-8 h-8 mr-3 mt-1">
                      <i class="material-icons mx-1">delete</i>
                    </button>
                  </div>
                </td>
              </tr>
              <!--******************************************************-->

              <tr v-if="element && element._id == item._id">
                <td colspan="6" class="border">
                  <div class="overflow-auto w-full rounded-2xl">
                    <table class="table w-full text-center">
                      <thead class="bg-gray-200 text-black">
                        <tr>
                          <th
                            class="px-5 py-2 bg-gray-100 text-center text-xs font-semibold text-gray-600 capitalize tracking-wider">
                          </th>
                          <th
                            class="px-5 py-2 bg-gray-100 text-center text-xs font-semibold text-gray-600 capitalize tracking-wider">
                            {{ $t("fullname") }}
                          </th>
                          <th
                            class="px-5 py-2 bg-gray-100 text-center text-xs font-semibold text-gray-600 capitalize tracking-wider">
                            {{ $t("type") }}
                          </th>
                          <th
                            class="px-5 py-2 bg-gray-100 text-center text-xs font-semibold text-gray-600 capitalize tracking-wider">
                            {{ $t("email") }}
                          </th>
                          <th
                            class="px-5 py-2 bg-gray-100 text-center text-xs font-semibold text-gray-600 capitalize tracking-wider">
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(value, index) in element.sellers" :key="index" :value="value._id">
                          <td class="px-6 py-4 whitespace-no-wrap border-gray-200">
                            <div class="text-xs leading-5 capitalize px-2 font-semibold rounded-full text-gray-700">
                            </div>
                          </td>
                          <td class="px-6 py-4 whitespace-no-wrap border-gray-200">
                            <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-800">
                              {{ value.fullName }}
                            </div>
                          </td>

                          <td class="px-6 py-4 whitespace-no-wrap border-gray-200">
                            <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-800">
                              {{ value.type }}
                            </div>
                          </td>

                          <td class="px-6 py-4 whitespace-no-wrap border-gray-200">
                            <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-800">
                              {{ value.email }}
                            </div>
                          </td>

                          <td class="px-6 py-4 whitespace-no-wrap border-gray-200">
                            <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900"></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>

              <!-- ************************************************************************************-->

              <tr v-if="
                elementTelConsultant && elementTelConsultant._id == item._id
              ">
                <td colspan="6" class="border">
                  <div class="overflow-auto w-full rounded-2xl">
                    <table class="table w-full text-center">
                      <thead class="bg-gray-200 text-black">
                        <tr>
                          <th
                            class="px-5 py-2 bg-gray-100 text-center text-xs font-semibold text-gray-600 capitalize tracking-wider">
                          </th>
                          <th
                            class="px-5 py-2 bg-gray-100 text-center text-xs font-semibold text-gray-600 capitalize tracking-wider">
                            {{ $t("fullname") }}
                          </th>
                          <th
                            class="px-5 py-2 bg-gray-100 text-center text-xs font-semibold text-gray-600 capitalize tracking-wider">
                            {{ $t("type") }}
                          </th>
                          <th
                            class="px-5 py-2 bg-gray-100 text-center text-xs font-semibold text-gray-600 capitalize tracking-wider">
                            {{ $t("email") }}
                          </th>
                          <th
                            class="px-5 py-2 bg-gray-100 text-center text-xs font-semibold text-gray-600 capitalize tracking-wider">
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(
                            value, index
                          ) in elementTelConsultant.teleConsultants" :key="index" :value="value._id">
                          <td class="px-6 py-4 whitespace-no-wrap border-gray-200">
                            <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-800"></div>
                          </td>
                          <td class="px-6 py-4 whitespace-no-wrap border-gray-200">
                            <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-800">
                              {{ value.fullName }}
                            </div>
                          </td>

                          <td class="px-6 py-4 whitespace-no-wrap border-gray-200">
                            <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-800">
                              {{ value.type }}
                            </div>
                          </td>

                          <td class="px-6 py-4 whitespace-no-wrap border-gray-200">
                            <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-800">
                              {{ value.email }}
                            </div>
                          </td>

                          <td class="px-6 py-4 whitespace-no-wrap border-gray-200">
                            <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-800"></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- *********************************************************************-->

          <div v-if="teams.length <= 0" class="bg-blue-100 py-8">
            <span class="w-full flex justify-center text-center py-3 border-b border-gray-200 text-base">
              <p class="font-semibold flex text-green-500 whitespace-no-wrap">
                <i class="material-icons mx-3">error</i>
                <span class="">{{ $t("no_teams_selected") }} </span>
              </p>
            </span>
          </div>
        </div>
        <!-- ************************************************************************************-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      teams: [],
      sellers: [],
      currentUser: {},
      element: {},
      elementTelConsultant: {},
      filterBar: false,
      loading: false,
      filters: [
        {
          key: "seller",
          name: "Seller",
          type: "select",
          value: null,
          values: [],
        },
      ],
    };
  },
  computed: {
    warhouseSelected() {
      return this.$store.getters["wharhouse/warhouseSelected"]({
        user: _.pick(this.currentUser, ["countries"]),
      });
    },
  },
  watch: {
    warhouseSelected: async function (oldVal, newVal) {
      await this.reset();
    },
  },
  async mounted() {
    await this.getUser();
    await this.getTeams({});

    await this.getSellers();
    this.filters[0].values = this.sellers;
  },
  methods: {
    async getUser() {
      const res = await this.$server.me("users");
      if (res.content) this.currentUser = res.content;
      else this.currentUser = [];
      //
    },
    /**********************************  Get Teams ********************************/
    async getTeams(filters) {
      filters["country"] = await this.warhouseSelected;
      const data = await this.$server.search("teams", filters);

      if (data.content.results) {
        this.teams = data.content.results;
      } else this.teams = [];
    },
    async getSellers() {
      const filter = {
        type: "seller",
        limit: 4000,
      };
      const res = await this.$server.search("users", filter);
      if (res.content.results) this.sellers = res.content.results;
      else this.sellers = [];
    },
    async reset(data) {
      const filters = {
        limit: this.limit,
      };
      await this.getTeams(filters);
    },
    async filtrer(data) {
      const filters = {
        sellers: data.seller,
      };
      //
      await this.getTeams(filters);
    },
    async refresh() {
      await this.getTeams({ limit: this.limit });
    },
    async remove(id, country) {
      this.$swal.fire({
        title: this.$t("confirm_delete_team"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;
          const resp = await this.$server.delete("teams", {
            id: id,
            country: country,
          });

          this.loading = false;
          if (resp && resp._id) {
            alert(this.$t("team_deleted"), "success");
            await this.refresh();
          } else alert(resp, "warning");
        }
      });
    },

    async search(data) {
      const filter = {
        name: data,
      };
      const res = await this.$server.find("teams", filter);
      if (res.content) this.teams = res.content;
      else this.teams = [];
    },
    /**********************************Function To show members of each team ***************** */
    showVariants(index) {
      if (this.element !== this.teams[index]) {
        this.element = this.teams[index];
        this.elementTelConsultant = {};
      } else this.element = null;
    },
    showVariantsTeleconsultant(index) {
      if (this.elementTelConsultant !== this.teams[index]) {
        this.elementTelConsultant = this.teams[index];
        this.element = {};
      } else this.elementTelConsultant = null;
    },
  },
};
</script>

<style></style>
